var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('CRow',[_c('CCol',{staticClass:"d-flex justify-content-end",attrs:{"sm":"12"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
            content: _vm.$t('label.modality'),
            placement: 'top-end',
          }),expression:"{\n            content: $t('label.modality'),\n            placement: 'top-end',\n          }"}],staticClass:"d-flex align-items-center",attrs:{"shape":"square","color":"add"},on:{"click":_vm.toggleModal}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t("label.nueva")))])],1)],1),_c('CCol',{attrs:{"sm":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"hover":"","sorter":"","column-filter":"","table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"items":_vm.formatedItems,"fields":_vm.fields,"loading":_vm.loading,"noItemsView":_vm.tableText.noItemsViewText,"items-per-page":5,"active-page":_vm.activePage,"pagination":""},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('loading')]},proxy:true},{key:"Status",fn:function({ item }){return [_c('td',{staticClass:"text-center align-middle"},[_c('div',[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(" "+_vm._s(_vm.$t("label." + item.Status))+" ")])],1)])]}},{key:"Details",fn:function({ item }){return [_c('td',{staticClass:"center-cell"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                    content: _vm.$t('label.edit') + ' ' + _vm.$t('label.modality'),
                    placement: 'top-end',
                  }),expression:"{\n                    content: $t('label.edit') + ' ' + $t('label.modality'),\n                    placement: 'top-end',\n                  }"}],staticClass:"mr-1",attrs:{"shape":"square","color":"edit","size":"sm"},on:{"click":function($event){return _vm.toggleModal(true, item)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1),(item.FgActModality)?_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                    content: _vm.$t('label.CircuitAssociation'),
                    placement: 'top-end',
                  }),expression:"{\n                    content: $t('label.CircuitAssociation'),\n                    placement: 'top-end',\n                  }"}],attrs:{"shape":"square","color":"watch","size":"sm"},on:{"click":function($event){return _vm.ModalityCircuit(item)}}},[_c('CIcon',{attrs:{"name":"cil-list-rich"}})],1):_vm._e()],1)]}}])})],1)],1),_c('modality-modal',{attrs:{"modal":_vm.modal,"modalityData":_vm.modality,"edit":_vm.edit},on:{"update:modal":function($event){_vm.modal=$event},"submited":_vm.handleSubmit}}),_c('modality-circuit-modal',{attrs:{"modal":_vm.ModalityCircuitmodal,"ModalityCircuit":_vm.ModalityCircuitItem},on:{"update:modal":function($event){_vm.ModalityCircuitmodal=$event},"submited":_vm.handleSubmit}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }