<template>
  <div>
    <loading-overlay :active="loading" :is-full-page="true" loader="bars" />
    <CModalExtended
      :title="tituloModal"
      :closeOnBackdrop="false"
      color="dark"
      size="lg"
      class="modal-extended"
      :show.sync="modalActive"
    >
      <CRow>
        <CCol sm="12">
          <CCard class="card-simple">
            <CCardBody>
              <CRow>
                <CCol sm="12">
                  <CRow>
                    <CCol sm="12">
                      <CSelect
                        size="sm"
                        :placeholder="$t('label.select')"
                        addLabelClasses="required text-right"
                        :label="$t('label.condition')"
                        :horizontal="{
                          label: 'col-sm-12 col-lg-4',
                          input: 'col-sm-12 col-lg-7',
                        }"
                        :options="conditionOptions"
                        :value.sync="$v.modalityInfo.ConditionPlannigId.$model"
                        :invalid-feedback="
                          errorMessage($v.modalityInfo.ConditionPlannigId)
                        "
                        :is-valid="hasError($v.modalityInfo.ConditionPlannigId)"
                      >
                      </CSelect>
                    </CCol>
                    <CCol sm="12">
                      <CInput
                        addLabelClasses="required text-right"
                        :placeholder="$t('label.modalityName')+' (ES)'"
                        :horizontal="{
                          label: 'col-sm-12 col-lg-4',
                          input: 'col-sm-12 col-lg-7',
                        }"
                        v-uppercase
                        :label="$t('label.modalityName')+' (ES)'"
                        size="sm"
                        maxlength="100"
                        minlength="3"
                        v-model.trim="$v.modalityInfo.ModalityNameEs.$model"
                        :invalid-feedback="errorMessage($v.modalityInfo.ModalityNameEs)"
                        :is-valid="hasError($v.modalityInfo.ModalityNameEs)"
                      />
                    </CCol>
                    <CCol sm="12">
                      <CInput
                        addLabelClasses="required text-right"
                        :placeholder="$t('label.modalityName')+' (EN)'"
                        :horizontal="{
                          label: 'col-sm-12 col-lg-4',
                          input: 'col-sm-12 col-lg-7',
                        }"
                        v-uppercase
                        :label="$t('label.modalityName')+' (EN)'"
                        size="sm"
                        maxlength="100"
                        minlength="3"
                        v-model.trim="$v.modalityInfo.ModalityNameEn.$model"
                        :invalid-feedback="errorMessage($v.modalityInfo.ModalityNameEn)"
                        :is-valid="hasError($v.modalityInfo.ModalityNameEn)"
                      />
                    </CCol>
                    <CCol sm="12" v-if="edit">
                      <div>
                        <CSelect
                          :value.sync="modalityInfo.Status"
                          :is-valid="statusSelectColor()"
                          :horizontal="{
                            label: 'col-sm-12 col-lg-4',
                            input: 'col-sm-12 col-lg-7',
                          }"
                          size="sm"
                          :label="$t('label.status')"
                          :options="statusOptions"
                          addLabelClasses="text-right"
                        />
                      </div>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <template #footer>
        <CButton
          v-if="!edit"
          color="add"
          shape="square"
          :disabled="isSubmit"
          @click="submit"
        >
          <div v-if="!isSubmit">
            <CIcon name="checkAlt" /><span class="ml-1">{{
              $t("button.accept")
            }}</span>
          </div>
          <div v-if="isSubmit">
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span class="ml-1">{{ $t("button.accept") }}</span>
          </div>
        </CButton>
        <CButton
          v-if="edit"
          color="add"
          shape="square"
          :disabled="isSubmit"
          @click="confirmation"
        >
          <div v-if="!isSubmit">
            <CIcon name="checkAlt" /><span class="ml-1">{{
              $t("button.accept")
            }}</span>
          </div>
          <div v-if="isSubmit">
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span class="ml-1">{{ $t("button.accept") }}</span>
          </div>
        </CButton>
        <CButton
          color="wipe"
          shape="square"
          :disabled="isSubmit"
          @click="toggle(false)"
        >
          <CIcon name="x" />&nbsp; {{ $t("button.cancel") }}
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
import ModalityValidation from "@/_validations/metalscrap/ModalityFormValidations.js";
import { mapState } from "vuex";
import ModalMixin from "@/_mixins/modal";
import General from "@/_mixins/general";
import MetalScrap from "@/_mixins/metalscrap";
import mixinServicio from "@/_mixins/servicio";

//DATA
function data() {
  return {
    modalActive: false,
    modalityInfo: {
      ModalityId: "",
      ModalityNameEs: '',
      ModalityNameEn: '',
      ConditionPlannigId: '',
      Status: 1,
    },
    oldStatus: 1,
    loading: false,
    conditionData: [],
  };
}

// METHODS
function toggle(newVal) {
  if (!newVal) this.resetForm();
  else if(this.edit && this.modalityData) this.loadData();
  if (newVal)  this.conditionList();
  this.modalActive = newVal;

}
function resetForm() {
  this.modalityInfo.ModalityId = '';
  this.modalityInfo.ModalityNameEs = '';
  this.modalityInfo.ModalityNameEn = '';
  this.modalityInfo.ConditionPlannigId = '';
  this.modalityInfo.Status = 1;
  this.oldStatus = 1;
  this.$v.$reset();
}

function loadData() {
  this.modalityInfo.ModalityId = this.modalityData.ModalityId;
  this.modalityInfo.ModalityNameEs = this.modalityData.ModalityNameEs;
  this.modalityInfo.ModalityNameEn = this.modalityData.ModalityNameEn;
  this.modalityInfo.ConditionPlannigId = this.modalityData.ConditionPlanningId;
  this.modalityInfo.Status = this.modalityData.FgActModality ? 1: 0;
  this.oldStatus = this.modalityData.FgActModality ? 1: 0;
  this.$v.$touch();
}
function formatedData() {
  if (!this.edit) {
    let newModality = {
      ...this.modalityInfo,
    };

    delete newModality.ModalityId;
    delete newModality.Status;

    return newModality;
  } else {
    return {
      ...this.modalityInfo,
    };
  }
}
function submit() {
  try {
    this.$v.modalityInfo.$touch();
    if (this.$v.modalityInfo.$error) {
      throw this.$t("label.errorsPleaseCheck");
    }
    this.isSubmit = true;
    let route = this.edit
      ? "Modality-update"
      : "Modality-insert";
    let ModalityJson = this.formatedData();
    let metodo = this.edit ? "PUT" : "POST";

    this.$http
      .ejecutar(metodo, route, ModalityJson, {
        root: "ModalityJson",
      })
      .then((response) => {
        this.$emit("submited");
        this.modalActive = false;
        this.$notify({
          group: "container",
          title: "¡" + this.$t("label.success") + "!",
          text: response.data.data[0].Response,
          type: "success",
        });
        this.resetForm();
      })
      .catch((err) => {
        this.$notify({
          group: "container",
          title: "¡Error!",
          text: err,
          type: "error",
        });
      })
      .then(() => {
        this.isSubmit = false;
      });
  } catch (e) {
    this.notifyError({ text: e });
  }
}

function confirmation() {
  try {
    this.$v.modalityInfo.$touch();
    if (this.$v.modalityInfo.$error) {
        throw this.$t("label.errorsPleaseCheck");
    }
    this.statusConfirmation(
      this.oldStatus,
      this.modalityInfo.Status,
      this.submit,
      `${this.$i18n.locale == 'es' ? this.modalityInfo.ModalityNameEs : this.modalityInfo.ModalityNameEn}`
    );
  } catch (e) {
    this.notifyError({ text: e });
  }
}


  function conditionList() {
    this.loading = true;
    this.$http.get("TpConditionPlanning-list")
    .then(response => {
      this.conditionData = response.data.data;
    }).catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
      this.loading = false;
    }).then(() => {
      this.loading = false;
    });
  }
//COMPUTED
function tituloModal() {
  if (!this.edit) {
    return this.$t("label.nueva") + " " + this.$t("label.modality");
  } else {
    return `${this.$t("label.edit")} ${this.$t("label.modality")}: ${this.$i18n.locale == 'es' ? this.modalityInfo.ModalityNameEs : this.modalityInfo.ModalityNameEn}`;
  }
}
function statusSelectColor() {
  return this.modalityInfo.Status === 1;
}

function conditionOptions(){
  if(this.conditionData.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.conditionData.map(function(e){
      chart.push({
          value: e.ConditionPlanningId, 
          label: e.ConditionPlanningName,
      })     
    })
    return chart;
  }
}
export default {
  name: "modality-modal",
  mixins: [ModalMixin, General, mixinServicio, MetalScrap],
  data,
  components: {},
  props: {
    modal: Boolean,
    edit: {
      type: Boolean,
      default: false,
    },
    modalityData: {
      type: null,
      default: "",
    },
  },
  validations: ModalityValidation,
  directives: {
    uppercase: {
      bind(el, _, vnode) {
        el.addEventListener("input", (e) => {
          e.target.value = e.target.value.toUpperCase();
          vnode.componentInstance.$emit("input", e.target.value.toUpperCase());
        });
      },
    },
  },
  methods: {
    loadData,
    toggle,
    resetForm,
    submit,
    confirmation,
    statusSelectColor,
    formatedData,
    conditionList,
  },
  computed: {
    tituloModal,
    conditionOptions,
    ...mapState({
      branch: (state) => state.auth.branch,
      user: (state) => state.auth.user,
    }),
  },
  watch: {
    //FUNCIONES DEL MODAL
    modal: function (val) {
      this.toggle(val);
    },
    modalActive: function (val) {
      this.$emit("update:modal", val);
    },
  },
};
</script>
<style lang="scss" scoped>
.block {
  cursor: pointer;
}

.card-simple {
  border: 0px;
  margin-bottom: 0 !important;
}
</style>