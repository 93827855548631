<template>
  <div>
    <CRow>
        <CCol sm="12" class="d-flex justify-content-end">
          <CButton
            shape="square"
            color="add"
            v-c-tooltip="{
              content: $t('label.modality'),
              placement: 'top-end',
            }"
            class="d-flex align-items-center"
            @click="toggleModal"
          >
            <CIcon name="cil-playlist-add" /><span class="ml-1">{{
              $t("label.nueva")
            }}</span>
          </CButton>
        </CCol>
        <CCol sm="12">
          <dataTableExtended
            class="align-center-row-datatable"
            hover
            sorter
            column-filter
            :table-filter="tableText.tableFilterText"
            :items-per-page-select="tableText.itemsPerPageText"
            :items="formatedItems"
            :fields="fields"
            :loading="loading"
            :noItemsView="tableText.noItemsViewText"
            :items-per-page="5"
            :active-page="activePage"
            pagination
          >
            <template #loading>
              <loading />
            </template>
            <template #Status="{ item }">
              <td class="text-center align-middle">
                <div>
                  <CBadge :color="getBadge(item.Status)">
                    {{ $t("label." + item.Status) }}
                  </CBadge>
                </div>
              </td>
            </template>

            <template #Details="{ item }">
              <td class="center-cell">
                  <CButton
                    shape="square"
                    color="edit"
                    size="sm"
                    class=" mr-1"
                    v-c-tooltip="{
                      content: $t('label.edit') + ' ' + $t('label.modality'),
                      placement: 'top-end',
                    }"
                    @click="toggleModal(true, item)"
                  >
                    <CIcon name="pencil" />
                  </CButton>
                  <CButton v-if="item.FgActModality"
                    shape="square"
                    color="watch"
                    size="sm"
                    v-c-tooltip="{
                      content: $t('label.CircuitAssociation'),
                      placement: 'top-end',
                    }"
                    @click="ModalityCircuit(item)"
                  >
                    <CIcon name="cil-list-rich" />
                  </CButton>
                 
              </td>
            </template>
          </dataTableExtended>
        </CCol>
    </CRow>  
      <modality-modal
        :modal.sync="modal"
        :modalityData="modality"
        :edit="edit"
        @submited="handleSubmit"
      />
      <modality-circuit-modal
        :modal.sync="ModalityCircuitmodal"
        :ModalityCircuit="ModalityCircuitItem"
        @submited="handleSubmit"
      />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { DateFormater } from "@/_helpers/funciones";
import General from "@/_mixins/general";
import ModalityModal from "./modality-modal";
import ModalityCircuitModal from "./modality-circuit-modal";

function fields() {
  let _lang = this.$i18n.locale;
  return [
    { key: "Nro", label: "#", _classes: "text-center", filter: false, _style: "width: 3%;",_classes:'text-center center-cell'},
    { key: "ModalityNameEs", label: this.$t("label.modalityName")+' (ES)',_style: "width: 19%;",_classes:'text-center center-cell'},
    { key: "ModalityNameEn", label: this.$t("label.modalityName")+' (EN)',_style: "width: 19%;",_classes:'text-center center-cell'},
    { key: _lang=='en' ? "ConditionPlannigNameEn" : "ConditionPlanningNameEs", label: this.$t("label.condition"),_style: "width: 13%;",_classes: "text-center center-cell"},
    { key: "Usuario", label: this.$t("label.user"), _style: "width: 11%;",_classes: "text-center center-cell"},
    { key: "Fecha", label: this.$t("label.date"), _classes: "text-center center-cell", _style: "width: 11%;", },
    { key: "Status", label: this.$t("label.status"), _classes: "text-center center-cell", _style: "width: 11%;", },
    { key: "Details",label: "",sorter: false,filter: false,_style: "min-width: 90px; width: 7%;",_classes: "text-center center-cell"},
  ];
}
function cellTableClasses() {
  return {
    Nro: "align-middle",
    ModalityNameEn: "align-middle",
    ModalityNameEs: "align-middle",
    ConditionPlannigNameEn: "align-middle",
    ConditionPlanningNameEs: "align-middle",
    Usuario: "align-middle",
    Fecha: "align-middle",
    Status: "align-middle",
  };
}

//DATA
function data() {
  return {
    isActive: false,
    ModalityCircuitItem: {},
    ModalityCircuitmodal: false,
    items: [],
    loading: false,
    modal: false,
    edit: false,
    modality: '',
    activePage: 1,

  };
}

//METHOD
function getModalityList() {
  this.loading = true;

  this.$http
    .get("Modality-list")
    .then((response) => {
      this.items = response.data.data;
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.loading = false;
    });
}


function ModalityCircuit(item) {
  this.ModalityCircuitItem=item;
  this.ModalityCircuitmodal = true;
}
function toggleModal(edit = false, item = null) {
  if (edit && item) {
    this.edit = edit;
    this.modality =  item;
  }else{
    this.edit = false;
  }

  this.modal = true;

}
function handleSubmit(){
  this.getModalityList();
}

function checkParameter(value) {
  return value ? 'SI' : 'NO';
}

function resetForm() {

   this.ModalityCircuitItem= {};
   this.ModalityCircuitmodal= false;
   this.items= [];
   this.modality= '';
  
}



// COMPUTED
function formatedItems() {
  return this.items.map((modality) =>
    Object.assign({}, modality, {
      Nro: modality.Nro,
      ModalityId: modality.ModalityId ? modality.ModalityId : '',
      ModalityCircuitJson: modality.ModalityCircuitJson,
      ModalityNameEn: modality.ModalityNameEn ? modality.ModalityNameEn : '',
      ModalityNameEs: modality.ModalityNameEs ? modality.ModalityNameEs : '',
      ConditionPlanningId: modality.ConditionPlanningId ? modality.ConditionPlanningId : '',
      ConditionPlannigNameEn: modality.ConditionPlannigNameEn ? modality.ConditionPlannigNameEn : '',
      ConditionPlanningNameEs: modality.ConditionPlanningNameEs ? modality.ConditionPlanningNameEs : '',
      Usuario: modality.TransaLogin ? modality.TransaLogin : "N/A",
      Fecha: modality.TransaRegDate
        ? DateFormater.formatOnlyDateWithSlash(modality.TransaRegDate)
        : "N/A",
      Status: modality.Status,
      _classes: modality.Status === "INACTIVO" ? "table-danger" : "",
      _cellClasses: this.cellTableClasses,
    })
  );
}
function getBranchId() {
  return this.branch ? this.branch.CompanyBranchId : "";
}

export default {
  name: "modality-list",
  mixins: [General],
  data,
  props:['tabIndex'],
  components: {
    ModalityCircuitModal,
    ModalityModal,
  },
  methods: {
    ModalityCircuit,
    getModalityList,
    toggleModal,
    handleSubmit,
    checkParameter,
    resetForm
  },
  watch: {
    tabIndex(newValue){
      if(newValue === 1){
         this.resetForm();
         this.getModalityList(); 
      }
    }
  },
  computed: {
    fields,
    formatedItems,
    cellTableClasses,
    getBranchId,
    ...mapState({
      branch: (state) => state.auth.branch,
      user: (state) => state.auth.user,
    }),
  },
  created() {
  },
  mounted() {},
};
</script>
<style scoped>

</style>